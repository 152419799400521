var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "pb-2" },
    [
      _c(
        "e-filters",
        {
          attrs: { searching: _vm.fetching },
          on: { search: _vm.filterData, reset: _vm.resetFiltersLocal },
        },
        [
          _c(
            "div",
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "4" } },
                    [
                      _c("e-store-combo", {
                        attrs: {
                          required: false,
                          placeholder: _vm.$t("Todas"),
                        },
                        model: {
                          value: _vm.filters.storeId,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "storeId", $$v)
                          },
                          expression: "filters.storeId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "4" } },
                    [
                      _c("e-person-search", {
                        attrs: {
                          id: "client",
                          label: _vm.$t("Cliente"),
                          "is-customer": "",
                          "only-active": false,
                        },
                        model: {
                          value: _vm.filters.customerId,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "customerId", $$v)
                          },
                          expression: "filters.customerId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "4" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "description",
                          label: _vm.$t("Descrição"),
                          placeholder: _vm.$t("Descrição"),
                        },
                        model: {
                          value: _vm.filters.description,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "description", $$v)
                          },
                          expression: "filters.description",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "4" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "nsu",
                          label: _vm.$t("NSU"),
                          placeholder: _vm.$t("NSU"),
                        },
                        model: {
                          value: _vm.filters.nsu,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "nsu", $$v)
                          },
                          expression: "filters.nsu",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "sale-id",
                          label: _vm.$t("Id da venda"),
                          type: "text-number",
                          precision: 0,
                        },
                        model: {
                          value: _vm.filters.saleId,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "saleId", $$v)
                          },
                          expression: "filters.saleId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "receivableOriginId",
                          label: _vm.$t("Id Receita de origem"),
                          type: "text-number",
                          precision: 0,
                        },
                        model: {
                          value: _vm.filters.receivableOriginId,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "receivableOriginId", $$v)
                          },
                          expression: "filters.receivableOriginId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "value",
                          label: _vm.$t("Valor"),
                          type: "text-number",
                          currency: "R$",
                          precision: 2,
                        },
                        model: {
                          value: _vm.filters.value,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "value", $$v)
                          },
                          expression: "filters.value",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "status",
                          type: "vue-select",
                          label: _vm.$t("Status"),
                          placeholder: _vm.$t("Todos"),
                          options: _vm.statusTypes,
                        },
                        model: {
                          value: _vm.filters.paymentStatus,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "paymentStatus", $$v)
                          },
                          expression: "filters.paymentStatus",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "4" } },
                    [
                      _c("e-payment-method", {
                        attrs: {
                          id: "payment_method",
                          multiple: "",
                          placeholder: _vm.$t("Todos"),
                        },
                        model: {
                          value: _vm.filters.paymentMethods,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "paymentMethods", $$v)
                          },
                          expression: "filters.paymentMethods",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "expense-period_type",
                          type: "vue-select",
                          label: _vm.$t("Tipo do período"),
                          options: _vm.receivablePeriodTypeOptions(),
                          clearable: false,
                        },
                        model: {
                          value: _vm.filters.periodType,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "periodType", $$v)
                          },
                          expression: "filters.periodType",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "4" } },
                    [
                      _c("FormulateInput", {
                        staticClass: "required",
                        attrs: {
                          id: "expense-period",
                          type: "date-range-picker",
                          label: _vm.$t("Período"),
                          "time-picker": false,
                          opens: "left",
                          validation: "requiredrange",
                          "validation-messages": {
                            requiredrange: _vm.$t("Informe um período"),
                          },
                        },
                        model: {
                          value: _vm.filters.period,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "period", $$v)
                          },
                          expression: "filters.period",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "b-row",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: false,
              expression: "false",
            },
          ],
          staticClass: "mb-2",
        },
        [
          _c(
            "b-col",
            { attrs: { md: "3" } },
            [
              _c(
                "e-total-summary-card",
                {
                  attrs: {
                    title: _vm.$t("Pendente"),
                    "title-class": "text-warning",
                    value: _vm.summaryData.pending.value,
                    "has-predicted-value": "",
                    "predicted-value": _vm.summaryData.pending.predicted,
                  },
                },
                [
                  _c("p", { staticClass: "mb-0" }, [
                    _vm._v(" Receb. abertos: "),
                    _c("br"),
                    _vm._v(
                      " " + _vm._s(_vm.summaryData.pending.secondValue) + " "
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "3" } },
            [
              _c(
                "e-total-summary-card",
                {
                  attrs: {
                    title: _vm.$t("Em atraso"),
                    "title-class": "text-danger",
                    value: _vm.summaryData.overdue.value,
                    "has-predicted-value": "",
                    "predicted-value": _vm.summaryData.overdue.predicted,
                  },
                },
                [
                  _c("p", { staticClass: "mb-0" }, [
                    _vm._v(" Receb. em atraso: "),
                    _c("br"),
                    _vm._v(
                      " " + _vm._s(_vm.summaryData.overdue.secondValue) + " "
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "3" } },
            [
              _c(
                "e-total-summary-card",
                {
                  attrs: {
                    title: _vm.$t("Quitado"),
                    "title-class": "text-primary",
                    value: _vm.summaryData.paidOff.value,
                  },
                },
                [
                  _c("p", { staticClass: "mb-0" }, [
                    _vm._v(" Total pago: "),
                    _c("br"),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("currency")(
                            _vm.summaryData.paidOff.secondValue
                          )
                        ) +
                        " "
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "3" } },
            [
              _c(
                "e-total-summary-card",
                {
                  attrs: {
                    title: _vm.$t("Total"),
                    "title-class": "text-info",
                    value: _vm.summaryData.total.value,
                    "has-predicted-value": "",
                    "predicted-value": _vm.summaryData.total.predicted,
                  },
                },
                [
                  _c("p", { staticClass: "mb-0" }, [
                    _vm._v(" Total pago: "),
                    _c("br"),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("currency")(_vm.summaryData.total.secondValue)
                        ) +
                        " "
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-card-actions",
        {
          attrs: { "action-refresh": "", "show-loading": _vm.fetching },
          on: { refresh: _vm.filterData },
        },
        [
          _c(
            "div",
            { attrs: { slot: "title" }, slot: "title" },
            [
              _c("e-page-size-selector", {
                attrs: { "per-page": _vm.paging.pageSize },
                on: { change: _vm.pageSizeChange },
              }),
            ],
            1
          ),
          _c("b-table", {
            ref: "bills-table",
            staticClass: "bordered receivable-select-row",
            attrs: {
              "show-empty": "",
              responsive: "",
              striped: "",
              small: "",
              "tbody-tr-class": _vm.rowClass,
              fields: _vm.fields,
              items: _vm.receivables,
              "empty-text": _vm.getEmptyTableMessage(
                _vm.$tc("RECEIVABLE.NAME"),
                "female"
              ),
              "no-local-sorting": "",
              "sort-by": _vm.sorting.sortBy,
              "sort-desc": _vm.sorting.sortDesc,
            },
            on: {
              "update:sortBy": function ($event) {
                return _vm.$set(_vm.sorting, "sortBy", $event)
              },
              "update:sort-by": function ($event) {
                return _vm.$set(_vm.sorting, "sortBy", $event)
              },
              "update:sortDesc": function ($event) {
                return _vm.$set(_vm.sorting, "sortDesc", $event)
              },
              "update:sort-desc": function ($event) {
                return _vm.$set(_vm.sorting, "sortDesc", $event)
              },
              "row-clicked": _vm.onRowClickToSelectPay,
              "sort-changed": _vm.getData,
            },
            scopedSlots: _vm._u([
              {
                key: "cell(action)",
                fn: function (row) {
                  return [
                    _c("e-grid-actions", {
                      attrs: {
                        "show-update": _vm.canUpdate(row.item),
                        "show-delete":
                          !_vm.allActionsBlocked(row.item) &&
                          _vm.canDelete(row),
                        "show-read-only": true,
                        buttons: _vm.gridActions(row),
                      },
                      on: {
                        "read-only": function ($event) {
                          return _vm.onShowDetails(row)
                        },
                        delete: function ($event) {
                          return _vm.onDeleteInstallment(row.item)
                        },
                        update: function ($event) {
                          return _vm.showUpdateSidebar(row)
                        },
                        "receive-account": function ($event) {
                          return _vm.showReceiveModal(row)
                        },
                        "reverse-payment": function ($event) {
                          return _vm.localReversePayment(row.item)
                        },
                      },
                    }),
                  ]
                },
              },
              {
                key: "head(bulkPay)",
                fn: function () {
                  return [
                    _c(
                      "p",
                      { staticClass: "ml-1" },
                      [
                        _c("e-instructions", {
                          attrs: {
                            title: _vm.$t("Baixa em massa"),
                            instruction: _vm.$t(
                              "Selecione os registros para baixar e depois vá no botão inferior direto da tela para ver as opções de recebimento em massa."
                            ),
                          },
                        }),
                        _c("FormulateInput", {
                          staticClass: "mt-1",
                          attrs: {
                            id: "input-check_all",
                            type: "e-checkbox",
                            title: _vm.$t(
                              "Marca ou desmarca todos os itens desta página."
                            ),
                          },
                          on: {
                            change: function (val) {
                              return _vm.onSelectAllToPay(val)
                            },
                          },
                          model: {
                            value: _vm.allCheckedToReceive,
                            callback: function ($$v) {
                              _vm.allCheckedToReceive = $$v
                            },
                            expression: "allCheckedToReceive",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "cell(bulkPay)",
                fn: function (row) {
                  return [
                    row.item.paymentDate
                      ? [
                          _c(
                            "p",
                            { staticClass: "h4" },
                            [
                              _c("b-icon", {
                                attrs: {
                                  icon: "check-circle-fill",
                                  variant: "primary",
                                  title: _vm.$t("Recebido"),
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      : _c(
                          "div",
                          { staticClass: "check-td" },
                          [
                            _c("FormulateInput", {
                              attrs: { type: "e-checkbox" },
                              on: {
                                change: function (val) {
                                  return _vm.onSelectToPay(val, row.item)
                                },
                              },
                              model: {
                                value: row.item.checkedToReceive,
                                callback: function ($$v) {
                                  _vm.$set(row.item, "checkedToReceive", $$v)
                                },
                                expression: "row.item.checkedToReceive",
                              },
                            }),
                          ],
                          1
                        ),
                  ]
                },
              },
              {
                key: "cell(store)",
                fn: function (row) {
                  return [
                    _vm._v(
                      " " + _vm._s(_vm._f("storeId")(row.item.receivable.store))
                    ),
                    _c("br"),
                  ]
                },
              },
              {
                key: "cell(customer)",
                fn: function (row) {
                  return [
                    row.item.receivable.customer
                      ? _c("div", [
                          _vm._v(
                            " " +
                              _vm._s(
                                row.item.receivable.customer.name ||
                                  row.item.receivable.customer.companyName
                              ) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "cell(issueDate)",
                fn: function (row) {
                  return [
                    row.item.receivable.issueDate
                      ? _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("date")(row.item.receivable.issueDate)
                              ) +
                              " "
                          ),
                        ])
                      : _c("span", [_vm._v(" - ")]),
                  ]
                },
              },
              {
                key: "cell(dueDate)",
                fn: function (row) {
                  return [
                    row.item.dueDate
                      ? _c("span", [
                          _vm._v(
                            " " + _vm._s(_vm._f("date")(row.item.dueDate)) + " "
                          ),
                        ])
                      : _c("span", [_vm._v(" - ")]),
                  ]
                },
              },
              {
                key: "cell(description)",
                fn: function (row) {
                  return [
                    _vm._v(
                      " " + _vm._s(row.item.receivable.description || "-") + " "
                    ),
                  ]
                },
              },
              {
                key: "cell(paymentMethod)",
                fn: function (row) {
                  return [
                    _c(
                      "span",
                      { attrs: { title: row.item.paymentMethod.name } },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("truncate")(
                                row.item.paymentMethod.name || "-",
                                20
                              )
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ]
                },
              },
              {
                key: "custom-foot",
                fn: function () {
                  return [
                    _c("tr", [
                      _c(
                        "th",
                        { staticClass: "text-right", attrs: { colspan: "7" } },
                        [_vm._v(" Total ")]
                      ),
                      _c("th", { staticClass: "text-right" }, [
                        _vm._v(
                          " " + _vm._s(_vm._f("currency")(_vm.totalValue)) + " "
                        ),
                      ]),
                      _c("th", { staticClass: "text-right" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm._f("currency")(_vm.totalPaidValue)) +
                            " "
                        ),
                      ]),
                      _c("th", { attrs: { colspan: "2" } }),
                    ]),
                    _vm.hasBulkPayment
                      ? _c("tr", [
                          _c(
                            "th",
                            {
                              staticClass: "text-right",
                              attrs: { colspan: "7" },
                            },
                            [_vm._v(" Total Selecionado ")]
                          ),
                          _c("th", { staticClass: "text-right" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("currency")(
                                    _vm.getTotalInstallmentsSelected
                                  )
                                ) +
                                " "
                            ),
                          ]),
                          _c("th", { attrs: { colspan: "2" } }),
                        ])
                      : _vm._e(),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c("e-pagination-summary", {
                    attrs: {
                      "current-page": _vm.paging.currentPage,
                      "per-page": _vm.paging.pageSize,
                      total: _vm.paging.rowCount,
                      "total-on-page": _vm.paging.rowsInCurrentPage,
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c("b-pagination", {
                    attrs: {
                      align: "right",
                      "total-rows": _vm.paging.rowCount,
                      "per-page": _vm.paging.pageSize,
                      "aria-controls": "accounts-payable-table",
                    },
                    on: { change: _vm.pageChange },
                    model: {
                      value: _vm.paging.currentPage,
                      callback: function ($$v) {
                        _vm.$set(_vm.paging, "currentPage", $$v)
                      },
                      expression: "paging.currentPage",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.hasBulkPayment
        ? _c("fab", {
            attrs: {
              "main-tooltip": _vm.$t("Ações"),
              "main-icon": "keyboard_command_key",
              "fixed-tooltip": true,
              actions: _vm.fabActions,
            },
            on: {
              "clean-bulk-receivement": _vm.cleanBulkPayemnt,
              "confirm-bulk-receivement": _vm.showBulkPayModal,
              "add-new": _vm.showCreateSidebar,
              click: function () {},
            },
          })
        : _vm._e(),
      !_vm.hasBulkPayment && _vm.$can("Create", "Receivable")
        ? _c("fab", {
            attrs: { "main-tooltip": _vm.$t("Adicionar Recebimento") },
            on: { click: _vm.showCreateSidebar },
          })
        : _vm._e(),
      _c("receive-installment-modal", {
        ref: "receiveInstallmentModal",
        on: {
          "after-confirm": function (ref) {
            var itemList = ref.itemList

            return _vm.onAfterPay(itemList)
          },
          "remove-installment": function (item) {
            _vm.removeInstallmentsToPay(item)
            _vm.getData()
          },
        },
      }),
      _c("receivable-sidebar", {
        ref: "receivable_sidebar",
        on: { save: _vm.getData },
      }),
      _c("receivable-installment-info-sidebar", {
        ref: "receivableInstallmentInfoSidebar",
        on: { "search-receivable-origin": _vm.onSearchReceivableOrigin },
      }),
      _c("fab", {
        attrs: {
          "main-tooltip": _vm.$t("Ações"),
          "main-icon": "keyboard_command_key",
          "fixed-tooltip": true,
          actions: _vm.fabActions,
        },
        on: {
          "clean-bulk-receivement": _vm.cleanBulkPayemnt,
          "confirm-bulk-receivement": _vm.showBulkPayModal,
          "add-new": _vm.showCreateSidebar,
          "add-import": _vm.createBulkBill,
          click: function () {},
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }